import $ from "jquery";

// smooth scroll
$(function () {
    $('a[href^="#"]').not('.slider_nav a')
        .on('click', function () {
            var adjust = 0;
            var speed = 400;
            var href = $(this).attr('href');
            var target = $(href == '#' || href == '' ? 'html' : href);
            var position = target.offset().top + adjust;
            $('body,html').animate({
                scrollTop: position
            }, speed, 'swing');
            return false;
        });
});

//nav
$(function () {
    $('.menuTrigger').on('click', function () {
        $(this).toggleClass('open');
        $('.head-nav').toggleClass('open');
    });
    $('header .nav a').on('click', function () {
        $('.head-nav').toggleClass('open');
    });
});

//Window height
$(window).on('load resize', function () {
    var hdr = $('.main-header');
    var hdrHeight = hdr.outerHeight(true);
    var btm = $('#kv .btm');
    var btmHeight = btm.outerHeight(true);
    var h = $(window).height();
    var cont = $('#kv').outerHeight(true);

    $('#kv .inner').css('height', h - hdrHeight - btmHeight);

});



//animation
$(window).on('load scroll', function () {
    $('.fadeUp').each(function () {
        var targetElement = $(this).offset().top;
        var scroll = $(window).scrollTop();
        var windowHeight = $(window).height();
        if (scroll > targetElement - windowHeight + 100) {
            $(this).addClass('scrollin');
        } else {
            $(this).removeClass('scrollin');
        }
    });
});


//accordion menu
$(function () {
    $('.faq_list dt').on('click', function () {
        $(this).next().slideToggle();
        $(this).toggleClass('open');
    });
});



//Voices carousel
var $owl = $('.slide_list');
$owl.owlCarousel({
    items: 1,
    loop: true,
    center: true,
    dots: false,
    autoplayHoverPause: true,
    autoplay: false,
    autoplayTimeout: 10000,
    autoplaySpeed: 2000,
    responsive: {
        0: {
            dots: true,
            nav: true,
            navText: '',
        },
        768: {
            nav: true,
            navText: '',
        }
    }
});

$(function () {
    $owl.on('changed.owl.carousel', function (event) {
        var cont = $owl.find('li').eq(event.item.index);
        var hash = cont.data('hash');

        $('.slider_nav li').removeClass('active');
        $('.slider_nav li[data-hash="' + hash + '"]').toggleClass('active');

    });
});


//footer
$(window).on('load scroll', function () {
    let target = $('.floatarea').offset().top;
    let currentPos = $(window).scrollTop() + window.innerHeight;
    if (currentPos > target) {
        $('.floating_btn_wrap').addClass('sticky');
    } else {
        $('.floating_btn_wrap').removeClass('sticky');
    }
});

$(function () {
    var btn = $('.floating_btn_wrap');
    $(window).on('load scroll', function () {
        if ($(this).scrollTop() > 100) {
            btn.addClass('active');
        } else {
            btn.removeClass('active');
        }
    });
});